import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Box, Flex } from '@chakra-ui/react'
import theme from '../../utils/theme'
import ModuledItem from '../../utils/moduledItem'

const Tab = styled.div`
  cursor: pointer;
  padding: ${theme.space[2]} ${theme.space[3]};
  border-bottom: 4px solid transparent;
  font-size: ${theme.fontSizes['md']};


  &:hover {
    color: ${theme.colors.secondary400};
    border-bottom: 4px solid ${theme.colors.primary};
  }
  &.active {
    color: ${theme.colors.secondary600};
    border-bottom: 4px solid ${theme.colors.primary};
    font-family: ${theme.fonts.regular};
  }
`

const Section = styled.section`background: ${p => theme.colors[p.background] ? theme.colors[p.background] : p.background};`

const Tabs = ({data, location}) => {
  const [activeTab, setActiveTab] = useState(0)
  const [activeContent, setActiveContent] = useState(data[0]?.tabContent)

  useEffect(() => {
    if(location.hash.length > 0) {
      const tab = data.findIndex(tab => tab._rawTabId.current === location.hash.slice(1))
      setActiveTab(tab)
      setActiveContent(data[tab]?.tabContent)
    }
  }, [location, data])

  const handleActiveTab = (index) => {
    setActiveTab(index)
    setActiveContent(data[index].tabContent)
  }

  return (
    <>
      <Section>
        <Container maxW={['5xl','5xl','5xl','5xl','5xl','6xl','8xl']} px={[3,3,3,3,4]} pt={[5]}>
          <Flex justify={'center'} align={'center'} flexWrap='wrap' borderBottom='1px solid' borderColor={'gray200'}>
            {data?.map((item, idx) => (
              <Box key={idx} >
                <Tab onClick={() => handleActiveTab(idx)} tabId={item._rawTabId.current} className={activeTab === idx && 'active'}>{item.tabTitle}</Tab>
              </Box>
            ))}
          </Flex>
        </Container>
      </Section>
      {activeContent?.map((section, idx) => (
        <Section key={idx} background={section?._rawBgColor?.color}>
          <Container maxW={['5xl','5xl','5xl','5xl','5xl','6xl','8xl']} px={[3,3,3,3,4]} py={[section?.verticalSpacing?.vertical || 6]}>
            {section.content.map((item, idx) => (
              <ModuledItem key={idx} module={item._type} data={item} />
            ))} 
          </Container>
        </Section>
      ))}
    </>
  )
}

export default Tabs
