import React from 'react'
import {Box, Flex } from "@chakra-ui/react";
import Images from "../Interactive/image";
import Title from "../Typographys/title";
import RichContent from "./RichContent";

const TitleBarWithSideImg = (props) => {
    const data = props?.data;
    const bgColor = data?.bgColor?.hex;
    const img = data?.sideImg?.asset?.gatsbyImageData;
    const title = data?.title;
    const description = data?.description
    const desNullCondition = data?.descriptionMobile?._rawContent == null
    const descriptionM = desNullCondition ? description : data?.descriptionMobile
    const imgRight = data?.imgRight == null ? "0px" : (data?.imgRight + 'px');
    const imgRightM = data?.imgRightMobile == null ? "0px" : ( data?.imgRightMobile + 'px');
    const brkPtMax = '1139px';

    return (
        <Flex bgColor={bgColor}
              direction={{base: 'column', nav: 'row'}}
              overflow='hidden'
              align= 'center' justify='space-between'
              pl={{base: '0px', nav: '110px'}}
              sx={{
                  '.raw-content_default p': {
                      mb: '0px',
                  },
                  '@media screen and (max-width: 1239px)': {
                      '.raw-content_default p': {
                          fontSize: 'calc(2vw - 4px)'
                      },
                      '.optionTitle p': {
                          fontSize: 'calc(2vw + 14px)'
                      }
                  },
                  [`@media screen and (max-width: ${brkPtMax})`]: {
                      '.optionTitle p': {
                          fontSize: '30px',
                      },
                      '.raw-content_default p': {
                          fontSize: '18px'
                      }
                  }
              }}
            >
            <Flex direction='column' order={{base: '2', nav: '1'}} padding={{base: '32px 16px', nav: '0px'}}
                  width={{base: '100%', nav: 'auto'}} bgColor={{base: '#f4f4f4', nav: 'transparent'}}
                  sx={{
                      'p': {
                          color: {base: '#3d3d3d', nav: 'white !important'},
                          textAlign: {base: 'center', nav: 'left'}
                      }
                  }}>
                <Title data={title} />
                {data?.description?._rawContent != null &&
                    <Box display={{base: 'none', nav: 'block'}}>
                    <RichContent data={description} />
                </Box>
                }
                { !desNullCondition &&
                    <Box display={{base: 'block', nav: 'none'}}>
                        <RichContent data={descriptionM}/>
                    </Box>
                }
            </Flex>
            <Box order={{base: '1', nav: '2'}} h={{base: '144px',md: '200px', nav: '275px'}} flex={'0 0 auto'}
                 alignSelf='flex-end' mr={{base: imgRightM, nav: imgRight}}>
                <Images image={img} height='100%' fillImage={true} />
            </Box>
        </Flex>
    );
}
export default TitleBarWithSideImg