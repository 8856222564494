//~ DEPENDENCIES
import React from 'react'
import { graphql } from 'gatsby'

//~ COMPONENTS
import Layout from '../components/layout'
import Seo from '../components/Seo/index'

//~ MEDIA

//~ MISCELLANEOUS
import TabsSection from '../components/Collections/tabs';
import Sections from '../components/Sections/sections';
import TitleBarImgBg from "../components/Collections/titleBarWithImgBg";
import TitleBarWithSideImg from "../components/Collections/TitleBarWithSideImg";

const TemplatePage = ({location, data, pageContext}) => {

  const { header, footer } = pageContext
  // topsection 
  const topSection = data?.sanityPage?.topSection
  // const topSectionTitles = topSection?.title
  // tabs
  const hasTabs = data?.sanityPage?.pageContainer?.hasTabs
  const tabsContent = data?.sanityPage?.pageContainer?.tabs
  const pageNormalContent = data?.sanityPage?.pageContainer?.pageNormalContent

  //? CONTEXT

  const getSpecificTopSection = (topSection) => {
    const section = topSection.section[0]
    const sectionType = section._type
    if (sectionType === 'simpleTitleImgBar') {
      return <TitleBarImgBg data={section} />
    } else if (sectionType === 'titleBarWithSideImg') {
      return <TitleBarWithSideImg data={section} />
    }
    return <h3>{sectionType} not implemented yet</h3>
  }

  return (
    <Layout header={header} footer={footer}>
      <Seo data={data?.sanityPage?.seo} location={location} />
      
      {
        topSection && (
          getSpecificTopSection(topSection)
        )
      }
      {
        hasTabs && <TabsSection location={location} data={tabsContent} />
      }
      {
        pageNormalContent && pageNormalContent?.map(
          (item, idx) => <Sections key={idx} section={item} />
        )
      }

    </Layout>
  )
}

export default TemplatePage

export const query = graphql`
  query PageQuery($slug: String) {
    sanityPage(slug: {current: {eq: $slug}}) {
      seo {
        title
        metaUrl
        metaDescription
        metaImage {
          asset {
            altText
            gatsbyImageData
            url
          }
        }
      }
      topSection {
        ...SanityTopSection
      }
      pageContainer {
        hasTabs
        tabs {
          tabTitle
          _rawTabId(resolveReferences: {maxDepth: 10})
          _rawTabContent(resolveReferences: {maxDepth: 10})
          tabContent {
            verticalSpacing {
              vertical
            }
            _rawBgColor
            content {
              ...SanityOptionTitle
              ...SanityColumnThree
              ...SanityColumnTwo
              ...SanityColumnFour
              ...SanityColumnFive
              ...SanityCardFrontBack
              ...SanityBlockContent
              ...SanityTextCta
              ...SanityTextLink
              ...SanityCtaButton
              ...SanityMainImage
              ...SanityTutorObj
              ...SanityCardExtended
              ...SanityCardLandscape
              ...SanitySubjectsCardsContainer
              ...SanityVideo
              ...SanityReview
              ...SanityFormSelector
            }
          }
        }
        pageNormalContent {
        internalName
        paddingTopDesktop
        paddingBottomDesktop
        paddingTopMobile
        paddingBottomMobile
          _rawBgColor
          content {
            ...SanityOptionTitle
            ...SanityStatsShowcase
            ...SanityColumnThree
            ...SanityColumnTwo
            ...SanityColumnFour
            ...SanityColumnFive
            ...SanityCardFrontBack
            ...SanitySectionDivider
            ...SanityPillarCards
            ...SanityBlockContent
            ...SanityJustText
            ...SanityTextCta
            ...SanityCtaButton
            ...SanityCtaButtonRef
            ...SanityMainImage
            ...SanityTutorObj
            ...SanityCardExtended
            ...SanityCardLandscape
            ...SanitySubjectsCardsContainer
            ...SanityTopSection
            ...SanityReview
            ...SanityFormSelector
            ...SanityVideo
          }
        }
      }
    }
  }
`
