import React from 'react'
import {Flex } from "@chakra-ui/react";
import Title from "../Typographys/title";
const TitleBarImgBg = (props) => {
    const data = props.data;
    const title = data?.title;
    const imgObj = data?.bgImg?.asset?.gatsbyImageData
    const imgSrc = imgObj?.images?.fallback?.src;
    return (
        <Flex align='center' justify='center'
              bgImage={`url(${imgSrc})`} bgRepeat='repeat-x' bgSize='auto 100%'
              h='144px' >
            <Title data={title} />
        </Flex>
    )
}
export default TitleBarImgBg